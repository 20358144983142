import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Layout/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/services',
    name: 'Servicios',
    component: ()=> import('@/views/Sections/ServicesView.vue'),
  },
  {
    path: '/contact',
    name: 'Contacto',
    component: ()=> import('@/views/Sections/ContactView.vue'),
  },
  {
    path: '/privacy',
    name: 'Aviso de Privacidad',
    component: ()=> import('@/views/Sections/PrivacyPolicy.vue'),
  },
]

const router = createRouter({
  scrollBehavior: function () {
    return { x: 0, y: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
