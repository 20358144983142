<template>
  <v-container fluid>
    <v-row>
      <v-carousel
        cycle
        :height="$vuetify.display.mobile ? '200' : '650'"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item src="@/assets/carousel/1.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/2.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/8.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/10.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/14.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/20.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/23.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/28.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/30.jpg" cover></v-carousel-item>
        <v-carousel-item src="@/assets/carousel/37.jpg" cover></v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row class="mt-7">
      <v-sheet
        class="d-flex align-center justify-center flex-wrap text-center mx-auto"
        width="100%"
        rounded
      >
        <div :class="$vuetify.display.mobile ? 'pl-3 pr-3' : 'pl-12 pr-12'">
          <h2 class="text-h4 font-weight-black text-gold my-5">JM CRUMEX</h2>

          <div class="text-h5 font-weight-medium mb-3 ">
            ¿Quiénes Somos?
          </div>

          <p class="text-body-1 mb-4 text-justify">
            Somos una empresa joven y con experiencia en el ramo de suministros
            y servicios industriales, Con la finalidad de ser parte de la
            solución eficaz y con calidad en sus proyectos. Contamos con la
            capacidad de suministro así como con un equipo profesional técnico
            con experiencia y amplios conocimientos en el área siempre
            dispuestos listo para asesorarle y proporcionarle las mejores
            soluciones para sus proyectos industriales.
          </p>
          <p class="text-body-1 mb-4 text-justify">
            Nuestro compromiso es proporcionar soluciones integrales y productos
            de alta calidad para satisfacer las necesidades de nuestros clientes
            en diversos sectores.
          </p>
        </div>
      </v-sheet>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" >
        <v-sheet
          border="md"
          class="text-white mx-auto pa-6"
         
          color="#141518"
          max-width="400"
          height="350"
        >
          <h4 class="text-h5 font-weight-bold mb-4">Misión</h4>

          <p class="mb-8 text-justify">
            En JM CRUMEX, nuestra misión es ser el socio preferido de la
            industria, proporcionando soluciones integrales en cables de acero,
            cadenas, eslingas, equipos de protección personal. Nos esforzamos
            por ofrecer productos de alta calidad que impulsen la seguridad, la
            eficiencia y el éxito de nuestros clientes.
          </p>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet
          border="md"
          class="pa-6 text-white mx-auto"
          color="#141518"
          max-width="400"
          height="350"
        >
          <h4 class="text-h5 font-weight-bold mb-4">Visión</h4>

          <p class="mb-8 text-justify">
            Nuestra visión es liderar la transformación industrial, siendo
            reconocidos como expertos en suministro y seguridad. Buscamos ser
            pioneros en innovación, sostenibilidad y servicio excepcional,
            contribuyendo al desarrollo de un mundo más seguro y productivo.
          </p>
        </v-sheet>
      </v-col>
      <v-col cols="12" md="4">
        <v-sheet
          border="md"
          class="pa-6 text-white mx-auto"
          color="#141518"
          max-width="400"
          height="350"
        >
          <h4 class="text-h5 font-weight-bold mb-4">Valores</h4>

          <p class="mb-8">
            Todos nuestros servicios estan regidos bajo los valores de 
            <br />

            <v-alert
            class="mt-2"
              border="start"
              color="gold"
              title="Cálidad"
              variant="tonal"
            >  </v-alert>
             <v-alert
             class="mt-2"
              border="start"
              color="gold"
              title="Profesionalismo"
              variant="tonal"
            >  </v-alert>
             <v-alert
             class="mt-2"
              border="start"
              color="gold"
              title="Honestidad "
              variant="tonal"
            >  </v-alert>
          </p>
        </v-sheet>
      </v-col>
    </v-row>
    <CrumexGallery class="mt-7 mb-7" />
  </v-container>
</template>
<script>
import { CrumexGallery } from "@/components";
export default {
  components: { CrumexGallery },
};
</script>