<template>
  <v-row align="center" class="fill-height" :class="$vuetify.display.mobile ? 'pl-3 pr-3' : 'pl-12 pr-12'" justify="center">
    <div class="text-h5 font-weight-medium mb-3 text-gold">
      Nuestros Servicios
    </div>

    <p class="text-body-1 mb-4 text-justify">
      Somos una empresa joven y con experiencia en el ramo de suministros y
      servicios industriales, Con la finalidad de ser parte de la solución
      eficaz y con calidad en sus proyectos. Contamos con la capacidad de
      suministro así como con un equipo profesional técnico con experiencia y
      amplios conocimientos en el área siempre dispuestos listo para asesorarle
      y proporcionarle las mejores soluciones para sus proyectos industriales.
    </p>
    <template v-for="(item, i) in items" :key="i">
      <v-col cols="12" md="4">
        <v-hover v-slot="{ isHovering, props }">
          <v-card
            :class="{ 'on-hover': isHovering }"
            :elevation="isHovering ? 12 : 2"
            hover
            v-bind="props"
          >

            <v-img :src="item.img" height="225px" cover>
              <v-card-title class="text-h6  d-flex flex-column" :class="isHovering ? 'text-white' : 'text-white'">
                <p>
                {{ item.title }}
              </p>
                <!-- <div class="title-bg" v-if="item.title.length > 0">
                  <p>
                    {{ item.title }}
                  </p>
                </div> -->

      
              </v-card-title>
              <div class="align-self-center">
                <!-- <v-btn
                    v-for="(icon, index) in icons"
                    :key="index"
                    :class="{ 'show-btns': isHovering }"
                    :color="transparent"
                    :icon="icon"
                    variant="text"
                  ></v-btn> -->
              </div>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-rewind", "mdi-play", "mdi-fast-forward"],
    items: [
      {
        title: "",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/4.jpg"),
      },
      {
        title: "Material de Papelería",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/19.jpg"),
      },
      {
        title: "Material de Soldadura",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/15.jpg"),
      },
      {
        title: "Equipos de Seguridad (EPP)",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/12.jpg"),
      },
      {
        title: "Abrasivos y Discos",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/34.jpg"),
      },
      {
        title: "Productos para Marcaje Industrial",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/41.jpg"),
      },
      {
        title: "Productos para plomería",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/22.jpg"),
      },
      {
        title: "Herramientas de Perforación",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/32.jpg"),
      },
      {
        title: "Máquinas para la Construcción",
        text: "",
        subtext: "",
        img: require("@/assets/gallery/38.jpg"),
      },
    ],
    transparent: "50, 47, 47, 0.5"//"rgba(255, 255, 255, 0)",
  }),
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.title-bg {
  height: 50px;
  line-height: 50px;
  background-color: rgba(50, 47, 47, 0.5) !important;
}
</style>