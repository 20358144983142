<template>
  <v-app class="app-main">
    <v-system-bar v-if="!$vuetify.display.mobile">
      <v-icon color="gold" class="mr-2" icon="mdi mdi-phone-dial"></v-icon>
      <span>+52 9933655547</span>
      <v-icon color="green" icon="mdi mdi-whatsapp" class="mx-2"></v-icon>
      <span>+52 9932279047</span>
      <v-icon color="red" icon="mdi mdi-email-outline" class="mx-2"></v-icon>
      <span class="mr-3">ventas@jmcrumex.com </span>
    </v-system-bar>
    <v-system-bar v-else color="gold"> </v-system-bar>
    <v-toolbar class="mt-5" color="primary">
      <v-app-bar-nav-icon
        v-if="$vuetify.display.mobile"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <img
        :src="require('@/assets/logo.png')"
        class="toolbar-images"
        width="50px"
        alt=""
      />
      <v-toolbar-title> JM CRUMEX </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tabs
        v-if="!$vuetify.display.mobile"
        v-model="tab"
        bg-color="primary"
        color="gold"
      >
        <v-tab value="one" to="/">Inicio</v-tab>
        <v-tab value="two" to="/services">Servicios</v-tab>
        <v-tab value="three" to="/contact">Contacto</v-tab>
      </v-tabs>
    </v-toolbar>

    <v-navigation-drawer v-model="drawer" location="left" temporary>
       <v-img :src="require('@/assets/carousel/1.jpg')" width="100%" cover> </v-img>
      <v-list bg-color="black"  nav class="fill-height">
        <v-list-item
        v-for="(item, i) in urls"
        :key="i"
        :value="item"
        color="gold"
        rounded="shaped"
        :to="item.link"
      > 
      <v-list-item-title v-text="item.title"></v-list-item-title>
      </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-fade-transition>
        <router-view />
      </v-fade-transition>
    </v-main>
    <CrumexFooter></CrumexFooter>
    <div>
      <div v-if="showChat" class="chat-container">
        <v-sheet
          elevation="12"
          max-width="600"
          rounded="lg"
          width="100%"
          class="pa-4 text-center mx-auto"
        >
          <v-icon
            class="mb-5"
            color="success"
            icon="mdi mdi-forum"
            size="30"
          ></v-icon>

          <h2 class="text-h6 mb-6">Su Seguridad es nuestro compromiso</h2>

          <p class="mb-4 text-medium-emphasis text-body-2">
            Estamos listos para atenderle
          </p>
          <v-textarea
            label="Mensaje de whatsapp"
            auto-grow
            variant="outlined"
            rows="3"
            row-height="25"
            shaped
            v-model="message"
          ></v-textarea>
          <v-divider class="mb-4"></v-divider>

          <div class="text-end">
            <v-btn
              class="text-none"
              color="success"
              rounded
              variant="flat"
              width="90"
              @click="sendMessage"
            >
              Enviar
            </v-btn>
          </div>
        </v-sheet>
        <!-- <textarea v-model="message" placeholder="Escribe tu mensaje aquí"></textarea>
        <button  @click="sendMessage">Enviar</button> -->
      </div>
      <v-btn
        color="whatsapp"
        icon="mdi mdi-whatsapp"
        size="large"
        class="floating-button"
        @click="toggleChat"
      ></v-btn>
    </div>
  </v-app>
</template>
<script>
import { CrumexFooter } from "@/components";
export default {
  components: { CrumexFooter },
  data: () => ({
    drawer: false,
    tab: null,
    showChat: false,
    number: "9932279047",
    message: "",
    urls: [
        { title: 'Inicio', link: '/' },
        { title: 'Servicios', link: '/services' },
        { title: 'Contacto', link: '/contact' },
      ],
  }),
  methods: {
    toggleChat() {
      this.showChat = !this.showChat;
    },
    sendMessage() {
      const url = `https://api.whatsapp.com/send?phone=${
        this.number
      }&text=${encodeURIComponent(this.message)}`;
      window.open(url);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#app {
  top: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: #fff;
  color: #2c3e50;
}

.toolbar-images {
  z-index: 99;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.chat-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 300px;
}

a,
a:hover,
a:focus {
  text-decoration: none !important;
}
a:focus {
  outline: none;
}
a {
  color: #588cc1;
}
</style>
