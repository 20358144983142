// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import DateFnsAdapter from '@date-io/date-fns'
import { es } from 'vuetify/locale'


export default createVuetify(
  {
    locale: {
      locale: 'es',
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'bcTheme',
      themes: {
        bcTheme: {
          dark: false,
          variables: {},
          colors: {
            background: '#fbfbfb',
            surface: '#ffffff',
            primary: '#012a4c',
            'primary-darken-1': '#0E619F',
            'primary-light-1': '#023859',
            secondary: '#eff2eb',
            darken: '#000000',
            gold: '#f0a500',
            'gold-midas': '#f0a500',
            whatsapp: '#4caf50',
            error: '#CF6679',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FB8C00'
          }
        }
      }
    }
  }
)
