<template>
  <v-container fluid class="footer-container">
    <v-row class="pa-7">
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1">Télefono</p>
        <p class="text-body-1 text-left">
          <i class="mdi mdi-phone"></i> +52 9933655547
        </p>
      </v-col>
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1">Nuestras Oficinas</p>
        <p class="text-body-2">
          <i class="mdi mdi-map-marker"></i> Calle Principal s/n, Gaviotas Sur,
          Centro Tabasco México.
        </p>
      </v-col>

      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1">Solicita información</p>
        <p class="text-body-3">
          <i class="mdi mdi-email-outline"></i> Envianos un email:
          ventas@jmcrumex.com
        </p>
      </v-col>
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1">Brochure digital</p>
        <p class="text-body-3" style="cursor: pointer" @click="downloadFile">
          <i class="mdi mdi-file-table"></i> Consulta nuestro folleto digital
        </p>
      </v-col>
      <v-col cols="12" md="12">
        <v-divider dark></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="3" class="pa-5">
        <v-img src="@/assets/logo.png" alt="footer logo" width="250" />
        <span class="text-subtitle-2 text-left"
          >Estamos comprometidos en ser el socio confiable de nuestros clientes,
          brindando productos y servicios excepcionales que contribuyan al éxito
          de sus proyectos y operaciones.</span
        >
      </v-col>
      <v-col cols="12" md="6" class="pa-5">
        <v-list dark class="text-left">
          <v-list-item two-line to="/services">
            <v-list-item-content>
              <v-list-item-title>Servicios</v-list-item-title>
              <v-list-item-subtitle>
                En JM CRUMEX, nos enorgullece ser su aliado estratégico. Nuestro
                equipo de expertos está listo para asesorarle y proporcionarle
                las mejores soluciones para sus proyectos industriales.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

           <v-list-item two-line to="/terms">
            <v-list-item-content>
              <v-list-item-title>Terminos & Condiciones</v-list-item-title>
              <v-list-item-subtitle>
                Sus compras están seguras.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
           <v-list-item two-line to="/privacy">
            <v-list-item-content>
              <v-list-item-title>Aviso de privacidad</v-list-item-title>
              <v-list-item-subtitle>
                Tu seguridad y confidencialidad de tus datos los tomamos muy
                enserio.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12" md="3" class="pa-5">
        <div class="single-footer-widget">
          <h3>Newsletter</h3>
          <p>
            No olvides suscribirte para recibir noticias, promociones e
            infromación, por favor llene el siguiente formulario. .
          </p>
          <form>
            <input type="email" placeholder="Email" />
            <button type="submit"><i class="far fa-envelope"></i></button>
          </form>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <small>
        powered by
        <a href="https://reinscom.com.mx"> REINSCOM TECHNOLOGIES </a></small
      >
    </v-row>
  </v-container>
</template>
  
  <script>
export default {
  name: "EudaniaFooter",

  methods: {
    downloadFile() {
      const link = document.createElement("a");
      link.href =
        "https://servrsc.com/resources/jm_crux/JM_CRUMEX-BROCHURE.pdf";
      link.download = "BROCHURE.pdf";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
  <style lang="scss" scoped>
.footer-container {
  background-color: #1e1e1e;
  color: #c7baba;
}

.mdi {
  font-size: 30px;
  margin-right: 5px;
}

.far {
  font-size: 18px;
}

.single-footer-widget li {
  margin-bottom: 5px;
}

.single-footer-widget li a:hover {
  color: #eee;
}

.single-footer-widget > form {
  background: #333 none repeat scroll 0 0;
  height: 35px;
  margin-top: 10px;
  position: relative;
}

.single-footer-widget input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  height: 100%;
  left: 0;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  color: #eee;
  width: 80%;
}

.single-footer-widget button {
  background: #ba9656 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-footer-widget button:hover {
  background: #464646 none repeat scroll 0 0;
}

.v-list {
  background-color: #1e1e1e !important;
  color: #c7baba !important;
}
.container-eudania-snack {
  position: relative;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
}
.snack-icon {
  display: inline-block;
  line-height: 0;
  max-width: 100%;
}

.snack-txt {
  display: block;
  margin: auto;
}


</style>
  